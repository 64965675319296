import React, { FC, ReactNode } from 'react';
import { Heading } from '@/components/common/Heading';
import { FirstAccessModal } from '@/components/common/FirstAccessModal';
import { Container } from './styles';
import Image from 'next/image';
import useTemplateController from './Template.controller';
import { TemplateProps } from './types';

export const Template: FC<TemplateProps> = ({
  children,
  showToggle = true,
  hideEstablishmentName = false,
}) => {
  const { establishment, isFranchise, establishmentGroup } =
    useTemplateController();

  const hasEstablishment = !establishment?.id && !establishmentGroup?.id;

  return (
    <Container hasEstablishment={hasEstablishment}>
      {establishment?.id && !isFranchise && <FirstAccessModal />}
      <Heading
        showNotification={false}
        showToggle={showToggle}
        setCloseNotification={() => {}}
        showEstablishmentName={!hideEstablishmentName}
      />
      {children}
      <div className="fixed z-10 right-[-25px] bottom-[-25px]">
        <a
          target="_blank"
          role="link"
          href="https://api.whatsapp.com/send?phone=551141182773&text=Ol%C3%A1%20Guru%2C%20preciso%20de%20ajuda...">
          <Image
            src="/whatsapp.svg"
            title="Whatsapp Seu Guru"
            alt="Whatsapp Seu Guru"
            className="w-24 h-24 transition-all hover:transition-all hover:w-28 hover:h-28"
            width={24}
            height={24}
          />
        </a>
      </div>
    </Container>
  );
};
