import { useEffect, useState } from 'react';
import { io, Socket } from 'socket.io-client';
import { SocketEvent } from '@/types/socketEvent';
import { webSocketEvents } from '@/constant/webSocketEvents';
import { useCheckinStore } from '@/store/checkin';

const webSocketURL = process.env.NEXT_PUBLIC_WEBSOCKET_URL;

const useSocket = (id: string) => {
  let socket: Socket;
  const { handleChangeRefreshCheckin, handleUpdateCheckin } = useCheckinStore();
  const [dataEvent, setDataEvent] = useState<SocketEvent | null>(null);

  useEffect(() => {
    if (!webSocketURL) return;

    socket = io(`${webSocketURL}${id}`);
    socket.connect();

    webSocketEvents.forEach(event => {
      socket.on(event, (data: SocketEvent) => {
        handleSocketEvent(event, data);
      });
    });

    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
  }, [id]);

  const handleSocketEvent = (event: string, data: SocketEvent) => {
    if (event === 'CHECKIN_CHANGE') {
      handleChangeRefreshCheckin(data?.refresh);
    }

    handleUpdateCheckin(data?.checkin);
    setDataEvent(data);
  };

  return { dataEvent, handleSocketEvent };
};

export default useSocket;
